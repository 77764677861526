import React from "react";
import style from "./TradalProcess.module.css";
import pyramid from "../../assests/Images/pyramid.png";
const StepTradingProcess = () => {
  const steps = [
    {
      id: 1,
      title: "Sign Up",
      description:
        "Create an account on the Tradal website and verify your business identity.",
    },
    {
      id: 2,
      title: "Find Trade Opportunities,Browse and Connect",
      description:
        "Explore available commodities and use the matchmaking feature to find suitable trade partners.",
    },
    {
      id: 3,
      title: "Initiate a Trade, Negotiate and Use Smart Contracts",
      description:
        "Agree on terms with your partner and create a smart contract for secure payment processing.",
    },
    {
      id: 4,
      title: "Monitor and Complete Track Shipment",
      description:
        "Use IoT tools to monitor your shipment in real-time, and finalise the transaction upon delivery.",
    },
  ];

  return (
    <div className="bg-white py-16">
      <div className="mx-auto w-full max-w-6xl p-4">
        <div className="text-start">
          <h2 className="text-3xl sm:text-4xl md:text-5xl font-semibold text-golden mb-4">
            Tradal's 4-Step Trading Process
          </h2>
          <p className="text-gray-600 mb-8 sm:mb-12">
            Here's a concise four-step process for using the Tradal platform:
          </p>
        </div>

        <div className="grid gap-6 sm:gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
          {steps.map((step) => (
            <div
              key={step.id}
              className="bg-white rounded-2xl p-6 shadow-sm flex flex-col items-center text-center"
            >
              <div className="rounded-full w-12 h-12 flex items-center justify-center font-semibold bg-gray-200 text-gray-800">
                {step.id}
              </div>
              <h3 className="text-lg sm:text-xl font-semibold text-gray-900 mt-4">
                {step.title}
              </h3>
              <p className="text-base sm:text-lg text-gray-600 mt-2">
                {step.description}
              </p>
            </div>
          ))}
        </div>

        <img className={style.img5} alt="" />
      </div>
    </div>
  );
};

export default StepTradingProcess;
