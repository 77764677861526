import React from "react";
import solution1 from "../../assests/Images/solution1.png";
import solution2 from "../../assests/Images/solution2.png";

const Solution = () => {
  const solutions = [
    {
      id: 0o1,
      title: "Automated Trading",
      description:
        "Tradal is USTD & LC compatible, with Blockchain, also due diligence is conducted and shared prior to trade. Tradal acts as broker and seller removing the need for long chains.",
    },
    {
      id: 0o2,
      title: "Escrow Services",
      description:
        "Escrow services provided by the Bank of Africa will ensure clients can feel safe in the knowledge money will only be released on trade completion",
    },
  ];

  return (
    <div className="bg-gray-50 py-16">
      <div className="mx-auto w-full max-w-6xl p-4">
        <div className="text-start">
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-semibold mb-11 text-golden">
            Solutions
          </h1>

          {/* Solutions Section */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
            {/* First Solution */}
            <div className="p-6 rounded-lg text-center">
              <div className="rounded-full w-12 h-12 flex items-center justify-center font-semibold bg-golden text-white mx-auto">
                {solutions[0].id}
              </div>
              <h3 className="text-lg sm:text-xl font-semibold mb-2 mt-4">
                {solutions[0].title}
              </h3>
              <p className="text-sm sm:text-base text-gray-600">
                {solutions[0].description}
              </p>
            </div>

            {/* Second Solution - similar updates to first solution */}
            <div className="p-6 rounded-lg text-center">
              <div className="rounded-full w-12 h-12 flex items-center justify-center font-semibold bg-golden text-white mx-auto">
                {solutions[1].id}
              </div>
              <h3 className="text-lg sm:text-xl font-semibold mb-2 mt-4">
                {solutions[1].title}
              </h3>
              <p className="text-sm sm:text-base text-gray-600">
                {solutions[1].description}
              </p>
            </div>
          </div>

          {/* Milestones Section */}
          <p className="text-lg font-semibold mb-4 ">User Targets</p>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {/* Milestone cards - keep existing content but update classes */}
            <div className="p-6 border border-gray-300 rounded-lg text-center">
              <h3 className="text-lg sm:text-xl font-semibold mb-2">10K</h3>
              <p className="text-sm sm:text-base">
                Within the first 3 months we intend to reach this milestone
              </p>
            </div>

            {/* Milestone 2 */}
            <div className="p-6 border border-gray-300 rounded-lg text-center">
              <h3 className="text-lg sm:text-xl font-semibold mb-2">50K</h3>
              <p className="text-sm sm:text-base">
                By month 6, we plan to have achieved this milestone
              </p>
            </div>

            {/* Milestone 3 */}
            <div className="p-6 border border-gray-300 rounded-lg text-center">
              <h3 className="text-lg sm:text-xl font-semibold mb-2">100K</h3>
              <p className="text-sm sm:text-base">
                By month 12, we plan to have reached this milestone or surpassed
                it.
              </p>
            </div>
          </div>

          {/* Images Section */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
            <div className="p-6">
              <img
                src={solution1}
                alt="Solution"
                className="w-full h-auto object-contain mx-auto"
              />
            </div>
            <div className="p-6">
              <img
                src={solution2}
                alt="Solution"
                className="w-full h-auto object-contain mx-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Solution;
