import React from "react";
import style from "./Partnership.module.css";
import partnership from "../../assests/Images/partnership.png";
import partnership2 from "../../assests/Images/partnership2.png";
const Partnership = () => {
  return (
    <div className="bg-white py-16">
      <div className="mx-auto w-full max-w-6xl p-4">
        <img className={style.img7} />

        <div className="text-start">
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-semibold mt-11 mb-11 text-golden">
            Working in Partnership
          </h1>

          <p className="text-base sm:text-lg mb-6 md:mb-10 text-gray-600">
            Strategic partnerships with government bodies on Tradal Go and
            Tradal Mass for verified buyers and sellers who are central to
            Tradal’s mission of streamlining commodity trade. These alliances
            allow Tradal to enhance procurement, transaction, and payment
            processes through real-time traceability and advanced analytics,
            promoting transparency, efficiency, and economic growth
          </p>
        </div>

        <div className="flex justify-center mt-8 md:mt-10">
          <img
            src={partnership2}
            className="w-80 max-w-2xl h-96 rounded-3xl"
            alt="Partnership illustration"
          />
        </div>

        <div className="flex justify-center my-9">
          <img src={partnership} alt="Process" className="w-80  max-w-2xl" />
        </div>

        <div className="text-start">
          <h1 className="text-2xl sm:text-2xl md:text-4xl font-bold mb-8">
            Government Incentives
          </h1>

          <div className="space-y-4">
            <p className="text-base sm:text-lg text-gray-600">
              <span className="text-stone-900 font-semibold">
                Cost Savings:{" "}
              </span>
              Tradal's efficient systems reduce administrative costs...
            </p>
            <p className="text-base sm:text-lg text-gray-600">
              <span className="text-stone-900 font-semibold">
                Data Insights:{" "}
              </span>
              Advanced analytics offer governments real-time tracking for
              informed decisions and compliance...
            </p>
            <p className="text-base sm:text-lg text-gray-600">
              <span className="text-stone-900 font-semibold">
                Economic Growth:{" "}
              </span>
              Tradal supports local businesses and opens funding and trade
              opportunities, promoting job creation and pan-African trade...
            </p>
            <p className="text-base sm:text-lg text-gray-600">
              <span className="text-stone-900 font-semibold">
                Compliance Support:{" "}
              </span>
              Automated regulatory reporting reduces compliance costs and aligns
              with global standards...
            </p>
            <p className="text-base sm:text-lg text-gray-600">
              <span className="text-stone-900 font-semibold">
                Cultural Sensitivity:{" "}
              </span>
              Tradal integrates Cultures, customs and traditions (CCT), helping
              governments build trade policies that respect local values...
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partnership;
