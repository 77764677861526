import React from "react";
import slogo1 from "../../assests/Images/Logo 5 (1).png";
import slogo2 from "../../assests/Images/Logo 5 (2).png";
import slogo3 from "../../assests/Images/Logo 6 (1).png";
import origin from "../../assests/Images/origin.jpg";
import refinement from "../../assests/Images/refinement.png";
import consumer from "../../assests/Images/consumer.jpg";
import phoneImage from "../../assests/Images/image.png";

const Ecological = () => {
  return (
    <div className="bg-gray-50 py-16">
      <div className="mx-auto w-full max-w-6xl p-4">
        <div className="text-start">
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-semibold mb-11 text-golden">
            Tradal Platforms
          </h1>

          <p className="text-base sm:text-lg mb-6 md:mb-10 text-gray-600">
            At the core of Tradal’s disruptive nature is its focus on
            streamlining complex, high- and low-volume commodities trade
            processes. Monitoring, reducing time, unnecessary waste, and costs.
            <br /> Our Eco-Cycle-System offers three platforms designed to
            create a significant dent in our global carbon footprint.
          </p>

          <div className="flex flex-col space-y-8">
            <div className="flex justify-center">
              <img
                src={phoneImage}
                alt="Mobile phone"
                className="h-auto max-h-72 w-auto"
              />
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
              <div className="p-6 border border-gray-300 rounded-lg">
                <img
                  src={slogo1}
                  alt="Tradal Go"
                  className="w-12 h-12 mx-auto mb-4"
                />
                <h3 className="text-lg sm:text-xl font-semibold mb-2 text-center">
                  Tradal Go
                </h3>
                <p className="text-sm sm:text-base text-gray-600 text-center">
                  Minimises Environmental Damage, Assures Compliance, &
                  Facilitates Trade Between Governments.
                </p>
              </div>

              <div className="p-6 border border-gray-300 rounded-lg">
                <img
                  src={slogo2}
                  alt="Tradal Hi"
                  className="w-12 mx-auto mb-4"
                />
                <h3 className="text-lg sm:text-xl font-semibold mb-2 text-center">
                  Tradal Mass
                </h3>
                <p className="text-sm sm:text-base text-gray-600 text-center">
                  Pairing verified sellers & buyers, Eliminates the Need for
                  Middlemen & drastically reduces trade times.
                </p>
              </div>

              <div className="p-6 border border-gray-300 rounded-lg">
                <img
                  src={slogo3}
                  alt="Tradal Lo"
                  className="w-12 mx-auto mb-4"
                />
                <h3 className="text-lg sm:text-xl font-semibold mb-2 text-center">
                  Tradal Spot
                </h3>
                <p className="text-sm sm:text-base text-pretty text-gray-600 text-center">
                  A Public Ecommerce marketplace & Enables SMEs to fund trades,
                  invest $1.00 and earn up to 5x for 7 months.
                </p>
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mt-8">
              <div className="p-6">
                <h3 className="text-lg sm:text-xl font-semibold mb-4 text-center">
                  Origin
                </h3>
                <img
                  src={origin}
                  alt="Origin"
                  className="w-full h-auto object-cover rounded-lg"
                />
              </div>

              <div className="p-6">
                <h3 className="text-lg sm:text-xl font-semibold mb-7">
                  Refinement
                </h3>
                <img
                  src={refinement}
                  alt="Refinement"
                  className="w-60 sm:w-56 md:w-96 lg:w-72 mx-auto mb-4 transition-all duration-300"
                />
              </div>

              <div className="p-6">
                <h3 className="text-lg sm:text-xl font-semibold mb-7">
                  Consumer
                </h3>
                <img
                  src={consumer}
                  alt="Consumer"
                  className="w-60 sm:w-56 md:w-96 lg:w-60 mx-auto mb-4 transition-all duration-300"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ecological;
