export default function CookiesModal({ onClose }) {
  return (
    <>
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 md:p-4 md:pb-4">
              <h2 className="text-2xl font-bold mb-4">Cookie Notice</h2>

              <p className="text-sm text-gray-700 mt-4">
                We use cookies and similar technologies that are essential for
                facilitating purchases, enhancing your shopping experience, and
                providing our services as outlined in our Cookie Notice. We also
                use these cookies to understand how customers interact with our
                services (e.g., tracking site traffic) to help us make
                improvements.
              </p>

              <p className="text-sm text-gray-700 mt-4">
                If you consent, we will also use cookies to enhance your
                shopping experience across Amazon stores as explained in our
                Cookie Notice. Your preference applies to both first-party and
                third-party advertising cookies used on this service. These
                cookies store or access standard device details, such as a
                unique identifier.
              </p>

              <p className="text-sm text-gray-700 mt-4">
                The 96 third-party entities utilizing cookies on this service do
                so to serve and evaluate personalized ads, gather audience
                insights, and develop and enhance products. Select "Decline" to
                refuse, or "Customize" to make more specific advertising
                selections, or for more information.
              </p>

              <p className="text-sm text-gray-700 mt-4">
                You can modify your preferences at any time by accessing Cookie
                Preferences, as mentioned in our Cookie Notice. To learn more
                about Amazon's use of personal data (such as your order history
                in the Amazon Store), please refer to our Privacy Notice.
              </p>

              <div className="mt-6 flex justify-end gap-3">
                <button
                  className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onClick={onClose}
                >
                  Decline
                </button>
                <button
                  className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onClick={onClose}
                >
                  Customize
                </button>
                <button
                  className="rounded-md bg-golden px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black hover:text-white"
                  onClick={onClose}
                >
                  Accept All
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
