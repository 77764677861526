import React from "react";
import about from "../../assests/Images/about.png";
const About = () => {
  return (
    <div className="bg-gray-50 py-16">
      <div className="mx-auto w-full max-w-6xl p-4">
        {/* Main Title Section */}
        <div className="text-start">
          <h2 className="text-3xl sm:text-4xl md:text-5xl font-semibold mb-11 text-golden">
            About Tradal
          </h2>
          <p className="text-base sm:text-lg mb-6 md:mb-10 text-gray-600">
            Tradal is a disruptive innovative Web 3.0 trading platform pairing
            verified buyers in the UK, Europe, and North America with verified
            trade partners across Africa. Our mission is to streamline the
            traditionally lengthy commodity trading process, reducing trade
            times from months to minutes, while championing sustainable
            practices that will lower Tradal's and its clients' carbon footprint
            by 73% over five years.
          </p>
        </div>

        {/* Bottom section with phone image */}
        <div className="flex flex-col-reverse lg:flex-row items-center lg:justify-between">
          <div className="lg:w-1/2 text-center mb-8 lg:mb-0">
            <img
              src={about}
              alt="Phone with app illustration"
              className="w-full max-w-2xl rounded-2xl"
            />
          </div>

          <div className="lg:w-1/2 text-start">
            <p className="text-base sm:text-lg mb-6 md:mb-10 text-gray-600">
              Tradal’s advanced platform leverages blockchain.IoT, and AI to
              create a secure, transparent, and eco-friendly trading
              environment.
            </p>
            <p className="text-base sm:text-lg mb-6 md:mb-10 text-gray-600">
              Focused on reducing costs, enhancing reliability, and fostering
              international partnerships.Through partnerships with financial
              institutions.Tradal enables secure transactions through Letters of
              Credit (LC).Providing users with unparalleled safety, reliability,
              and efficiency in global trade.
            </p>
            <p className="text-base sm:text-lg mb-6 md:mb-10 text-gray-600">
              Through partnerships with financial institutions such as the Bank
              of Africa, Tradal enables secure transactions through Letters of
              Credit (LC), providing users with unparalleled safety,
              reliability, and efficiency in global trade.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
