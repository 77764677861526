import React from "react";
import section1 from "../../assests/Images/Section (1).png";
import section2 from "../../assests/Images/Section (2).png";
import section3 from "../../assests/Images/Section (3).png";
import section4 from "../../assests/Images/Section (4).png";
import section5 from "../../assests/Images/Section (5).png";
import section6 from "../../assests/Images/Section (6).png";
import section7 from "../../assests/Images/Section (7).png";
import section8 from "../../assests/Images/Section (8).png";
import section9 from "../../assests/Images/Section (9).png";
import section10 from "../../assests/Images/Section (10).png";
import section11 from "../../assests/Images/Section (11).png";
import section12 from "../../assests/Images/Section (12).png";
import section13 from "../../assests/Images/section(13).png";
import section14 from "../../assests/Images/section(14).png";
import section15 from "../../assests/Images/section(15).png";
import section16 from "../../assests/Images/section(16).png";
import section17 from "../../assests/Images/section(17).png";
import section18 from "../../assests/Images/section(18).png";

const Supporters = () => {
  return (
    // <section className="bg-gray-50 py-16">
    //   <div className="text-center mb-8">
    //     <h2 className="text-4xl font-bold">Supporters</h2>
    //   </div>
    //   <div className="flex flex-wrap justify-center gap-8 mx-auto w-full max-w-6xl">
    //     {supporters.map((supporter, index) => (
    //       <img
    //         key={index}
    //         src={supporter.src}
    //         alt={supporter.alt}
    //         className="h-28 w-auto bg-white p-4 rounded-lg transition-transform duration-300 hover:scale-105"
    //       />
    //     ))}
    //   </div>
    // </section>

    <div className="bg-gray-50 py-16">
      <h2 className="text-start mb-8 mx-auto w-full max-w-6xl p-4 text-3xl sm:text-4xl md:text-5xl font-semibold text-golden">
        Partners & Supporters
      </h2>

      <div className="flex flex-wrap justify-center gap-8 mx-auto w-full max-w-6xl">
        {/* First Row */}
        <div className="flex flex-wrap justify-center gap-4 w-full">
          <img
            src={section12}
            alt="Supporter 12"
            className="h-28 w-auto bg-white p-4 rounded-lg"
          />
          <img
            src={section1}
            alt="Supporter 1"
            className="h-28 w-auto bg-white p-4 rounded-lg"
          />
          <img
            src={section2}
            alt="Supporter 2"
            className="h-28 w-auto bg-white p-4 rounded-lg"
          />
          <img
            src={section3}
            alt="Supporter 3"
            className="h-28 w-auto bg-white p-4 rounded-lg"
          />
          <img
            src={section4}
            alt="Supporter 4"
            className="h-28 w-auto bg-white p-4 rounded-lg"
          />
          <img
            src={section5}
            alt="Supporter 5"
            className="h-28 w-auto bg-white p-4 rounded-lg"
          />
          <img
            src={section6}
            alt="Supporter 6"
            className="h-28 w-auto bg-white p-4 rounded-lg"
          />
        </div>

        <div className="flex flex-wrap justify-center gap-4 w-full mt-4">
          <img
            src={section7}
            alt="Supporter 7"
            className="h-28 w-auto bg-white p-4 rounded-lg"
          />
          <img
            src={section8}
            alt="Supporter 8"
            className="h-28 w-auto bg-white p-4 rounded-lg"
          />
          <img
            src={section9}
            alt="Supporter 9"
            className="h-28 w-auto bg-white p-4 rounded-lg"
          />
          <img
            src={section10}
            alt="Supporter 10"
            className="h-28 w-auto bg-white p-4 rounded-lg"
          />
          <img
            src={section11}
            alt="Supporter 11"
            className="h-28 w-auto bg-white p-4 rounded-lg"
          />
        </div>
        {/* <div className="flex flex-wrap justify-center gap-4 w-full mt-4">
          <img
            src={section13}
            alt="Supporter 13"
            className="h-28 w-auto bg-white p-4 rounded-lg"
          />
          <img
            src={section14}
            alt="Supporter 14"
            className="h-28 w-auto bg-white p-4 rounded-lg"
          />
          <img
            src={section15}
            alt="Supporter 15"
            className="h-28 w-auto bg-white p-4 rounded-lg"
          />
          <img
            src={section16}
            alt="Supporter 16"
            className="h-28 w-auto bg-white p-4 rounded-lg"
          />
          <img
            src={section17}
            alt="Supporter 17"
            className="h-28 w-auto bg-white p-4 rounded-lg"
          />
          <img
            src={section18}
            alt="Supporter 18"
            className="h-28 w-auto bg-white p-4 rounded-lg"
          />
        </div> */}
      </div>
    </div>
  );
};

export default Supporters;
