import React from "react";
import dataSecurity from "../../assests/Images/dataSecurity.png";
import solution1 from "../../assests/Images/solution1.png";

import style from "./DataSecurity.module.css";

const DataSecurity = () => {
  const cardData = [
    {
      title: "Data Security for Tradal",
      description:
        "Powered by Pangea Ai quantum computer, to ensure data security, Tradal employs several key measures",
    },
    {
      title: "Blockchain Technology",
      description:
        "Tradal utilises blockchain for immutable transaction records, ensuring transparency and trust in every trade.",
    },
    {
      title: "Financial Partnerships ",
      description:
        "Collaborations with financial institutions facilitate secure transactions through Letters of Credit (LC), enhancing the safety and reliability of trading activities.",
    },
    {
      title: "Advanced Encryption ",
      description:
        "Sensitive information is protected by state-of-the-art encryption protocols, safeguarding data during transmission and storage.",
    },
    {
      title: "Decentralised Messaging Systems",
      description:
        " Tradal employs decentralised messaging systems to minimise risks associated with centralised data storage, further protecting user information from potential breaches.",
    },
  ];

  return (
    <div className="bg-gray-50 py-16">
      <div className="mx-auto w-full max-w-6xl p-4">
        <div className="text-start">
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-semibold mb-11 text-golden">
            Data Security
          </h1>

          <div className="w-full flex justify-center items-center my-8 md:mt-0">
            <img
              src={dataSecurity}
              alt="Data Security"
              className="w-80 md:w-2/5 max-w-2xl rounded-2xl"
            />
          </div>
          <div className="flex flex-col md:flex-row items-start gap-8">
            <div className="w-full">
              {cardData.map((card, index) => (
                <div
                  key={index}
                  className="bg-white rounded-2xl p-6 mb-6 shadow-sm"
                >
                  <h2 className="text-lg sm:text-xl font-semibold mb-3">
                    {card.title}
                  </h2>
                  <p className="text-base sm:text-lg text-gray-600">
                    {card.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataSecurity;
