import React from "react";

export default function PrivacyModal({ onClose }) {
  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white rounded-lg overflow-auto max-w-3xl max-h-[80vh] p-6 relative z-60 mx-6">
          <h2 className="text-2xl font-bold mb-4">Website Privacy Policy</h2>
          <p className="text-sm text-gray-700 mb-4">
            We are committed to protecting your privacy and personal data...
          </p>
          <h3 className="text-xl font-semibold mt-4">1. Introduction</h3>
          <p className="text-sm text-gray-700 mt-4">
            1.1 Important information and who we are Welcome to Tradal Ltd’s
            Privacy and Data Protection Policy (“Privacy Policy”). At Tradal Ltd
            (“we”, “us”, or “our”) we are committed to protecting and respecting
            your privacy and Personal Data in compliance with the United Kingdom
            General Data Protection Regulation (“GDPR”), the Data Protection Act
            2018, and all other mandatory laws and regulations of the United
            Kingdom. This Privacy Policy explains how we collect, process, and
            keep your data safe. The Privacy Policy will tell you about your
            privacy rights, how the law protects you, and inform our employees
            and staff members of all their obligations and protocols when
            processing data. The individuals from which we may gather and use
            data can include:
          </p>
          <ul className="list-disc text-gray-700 text-sm pl-9 mt-4">
            <li>Customers</li>
            <li>Suppliers</li>
            <li>Business contacts</li>
            <li>Employees/Staff Members</li>
            <li>Third parties connected to your customers</li>
            <li>
              Any other people that the organisation has a relationship with or
              may need to contact.
            </li>
          </ul>
          <p className="text-sm text-gray-700 mt-4">
            This Privacy Policy applies to all our employees and staff members
            and all Personal Data processed at any time by us.
          </p>
          <p className="text-sm text-gray-700 mt-4">
            1.2 Your Data Controller and Data Protection Officer Tradal Ltd is
            your Data Controller and is responsible for your Personal Data. We
            have appointed a data protection officer (“DPO”) who is responsible
            for overseeing questions in relation to this Privacy Policy. If you
            have any questions about this Privacy Policy, including any requests
            to exercise your legal rights surrounding your Personal Data, please
            contact the DPO using the details set out below: Name: 0 Email: 0
          </p>
          <p className="text-sm text-gray-700 mt-4">
            Postal address: Tradal Ltd, 11 Sunnymede, Chigwell, Essex IG76ES,
            United Kingdom
          </p>
          <p className="text-sm text-gray-700 mt-4">
            You have the right to make a complaint at any time to the
            Information Commissioner’s Office (ICO), the UK supervisory
            authority for data protection issues (www.ico.org.uk). We would,
            however, appreciate the chance to deal with your concerns before you
            approach the ICO, so please contact us in the first instance.
          </p>
          <p className="text-sm text-gray-700 mt-4">
            1.3 Processing data on behalf of a Controller and processors’
            responsibility to you In discharging our responsibilities as a Data
            Controller, we have employees who will deal with your data on our
            behalf (known as “Processors”). The responsibilities below may be
            assigned to an individual or may be taken to apply to the
            organisation as a whole. The Data Controller and our Processors have
            the following responsibilities:
          </p>
          <ul className="list-disc text-gray-700 text-sm pl-9 mt-4">
            <li>
              Ensure that all processing of Personal Data is governed by one of
              the legal bases laid out in the GDPR (see 2.2 below for more
              information);
            </li>
            <li>
              Ensure that Processors authorised to process Personal Data have
              committed themselves to confidentiality or are under an
              appropriate statutory obligation of confidentiality;
            </li>
            <li>
              Implement appropriate technical and organisational measures to
              ensure a level of security appropriate to the risk associated with
              the processing of Personal Data;
            </li>
            <li>
              Obtain the prior specific or general authorisation of the
              Controller before engaging another Processor;
            </li>
            <li>
              Assist the Controller in the fulfilment of the Controller's
              obligation to respond to requests for exercising the data
              subject's rights;
            </li>

            <li>
              Make available to the Controller all information necessary to
              demonstrate compliance with the obligations laid down in the GDPR
              and allow for and contribute to audits, including inspections,
              conducted by the Controller or another auditor mandated by the
              Controller;
            </li>
            <li>
              Maintain a record of all categories of processing activities
              carried out on behalf of a Controller;
            </li>
            <li>
              Cooperate, on request, with the supervisory authority in the
              performance of its tasks;
            </li>
            <li>
              Designate a data protection officer where required by the GDPR,
              publish their details and communicate them to the supervisory
              authority;
            </li>
            <li>
              Support the data protection officer in performing their tasks by
              providing resources necessary to carry out those tasks and access
              to Personal Data and processing operations and to maintain their
              expert knowledge;
            </li>
            <li>
              Ensure that any person acting under the authority of the Processor
              who has access to Personal Data does not process Personal Data
              except on instructions from the Controller; and
            </li>
            <li>
              Designate a data protection officer where required by the GDPR,
              publish their details and communicate them to the supervisory
              authority;
            </li>
            <li>
              Notify the Controller without undue delay after becoming aware of
              a Personal Data Breach.
            </li>
          </ul>
          <h3 className="text-xl font-semibold mt-4">
            2. Legal Basis For Data Collection
          </h3>
          <p className="text-sm text-gray-700 mt-4">
            2.1 Types of data / Privacy policy scope “Personal Data” means any
            information about an individual from which that person can be
            identified. It does not include data where the identity has been
            removed (anonymous data). We may collect, use, store, and transfer
            different kinds of Personal Data about you which we have grouped
            together below. Not all of the following types of data will
            necessarily be collected from you, but this is the full scope of
            data that we collect and when we collect it from you:
          </p>
          <ul className="list-disc text-gray-700 text-sm pl-9 mt-4">
            <li>
              Profile/Identity Data: This is data relating to your first name,
              last name, gender, date of birth.
            </li>
            <li>
              Contact Data: This is data relating to your phone number,
              addresses, email addresses, phone numbers.
            </li>
            <li>
              Marketing and Communications Data: This is your preferences in
              receiving marketing information and other information from us.
            </li>
            <li>
              Billing Data: This is information relating to your debit and
              credit card information such as the name attached to your payment
              details and your billing address.s
            </li>
            <li>
              Financial Data: These are your banking details e.g. your account
              number and sort code.
            </li>
            <li>
              Transactional Data: This is information of details and records of
              all payments you have made for our services or products.
            </li>
          </ul>
          <p className="text-sm text-gray-700 mt-4">
            We also collect, use, and share Aggregated Data such as 0.
            Aggregated Data could be derived from your Personal Data but is not
            considered Personal Data in law as this data will not directly or
            indirectly reveal your identity. However, if we combine or connect
            Aggregated Data with your Personal Data so that it can directly or
            indirectly identify you, we treat the combined data as Personal Data
            which will be used in accordance with this Privacy Policy. We may
            also aggregate data to enable research or analysis so that we can
            better understand and serve you and others. For example, we may
            conduct research on your demographics and usage. Although this
            aggregated data may be based in part on Personal Data, it does not
            identify you personally. We may share this type of anonymous data
            with others, including service providers, our affiliates, agents,
            and current and prospective business partners. For the purposes of
            our business, we also collect what is known under the GDPR as
            special categories of Personal Data. We collect the following types
            of special Personal Data:
          </p>
          <ul className="list-disc text-gray-700 text-sm pl-9 mt-4">
            <li>
              Political Data: Information about race, or ethnicity, religious
              beliefs, sexual orientation, and political opinions
            </li>
            <li>Trade Union Membership Data</li>
            <li>
              Health Data: Information about health, including any medical
              condition, health, and sickness records
            </li>
            <li>Genetic information and biometric data</li>
            <li>Information about criminal convictions and offences</li>
          </ul>
          <p className="text-sm text-gray-700 mt-4">
            In order to collect this special kind of data, we need enhanced
            legal justifications beyond the ordinary legal justifications under
            the GDPR. We explain which justifications we rely on in paragraph
            2.2 below.
          </p>
          <p className="text-sm text-gray-700 mt-4">
            2.2 The Legal Basis for Collecting That Data There are a number of
            justifiable reasons under the GDPR that allow collection and
            processing of Personal Data. The main avenues we rely on are:
          </p>
          <ul className="list-disc text-gray-700 text-sm pl-9 mt-4">
            <li>
              “Consent”: Certain situations allow us to collect your Personal
              Data, such as when you tick a box that confirms you are happy to
              receive email newsletters from us, or ‘opt in’ to a service.
            </li>
            <li>
              “Contractual Obligations”: We may require certain information from
              you in order to fulfil our contractual obligations and provide you
              with the promised service.
            </li>
            <li>
              “Legal Compliance”: We’re required by law to collect and process
              certain types of data, such as fraudulent activity or other
              illegal actions.
            </li>
            <li>
              “Legitimate Interest”: We might need to collect certain
              information from you to be able to meet our legitimate interests -
              this covers aspects that can be reasonably expected as part of
              running our business, that will not have a material impact on your
              rights, freedom, or interests. Examples could be your address, so
              that we know where to deliver something to, or your name, so that
              we have a record of who to contact moving forwards.
            </li>
            <li>Information about criminal convictions and offences</li>
          </ul>
          <p className="text-sm text-gray-700 mt-4">
            For the special categories of data that we collect, the enhanced
            legal justification we rely on is: (a) The data subject has given
            explicit consent to the processing of such Personal Data for one or
            more specified purposes (“Explicit Consent”). (b) Processing is
            necessary for the purposes of carrying out the obligations and
            exercising specific rights of the controller or of the data subject
            in the field of employment and social security and social protection
            law in so far as it is authorised by Union or Member State law or a
            collective agreement pursuant to Member State law providing for
            appropriate safeguards for the fundamental rights and the interests
            of the data subject (“Necessity”). (c) Processing is necessary to
            protect the vital interests of the data subject or of another
            natural person where the data subject is physically or legally
            incapable of giving consent (“Incapacity”). (d) Processing is
            carried out in the course of its legitimate activities with
            appropriate safeguards by a foundation, association, or any other
            not-for-profit body with a political, philosophical, religious, or
            trade union aim and on condition that the processing relates solely
            to the members or to former members of the body or to persons who
            have regular contact with it in connection with its purposes and
            that the Personal Data are not disclosed outside that body without
            the consent of the data subjects (“Private Association”). (e)
            Processing relates to Personal Data which are manifestly made public
            by the data subject (“Already Public”). (f) Processing is necessary
            for the establishment, exercise, or defence of legal claims or
            whenever courts are acting in their judicial capacity (“Defence of
            Legal Claim”).
          </p>
          <h3 className="text-xl font-semibold mt-4">
            3. How we use your personal data
          </h3>
          <p className="text-sm text-gray-700 mt-4">
            3.1 Our data uses We will only use your Personal Data when the law
            allows us to.
          </p>
          <p className="text-sm text-gray-700 mt-4">
            3.2 Marketing and content updates You will receive marketing and new
            content communications from us if you have created an account and
            chosen to opt into receiving those communications. From time to time
            we may make suggestions and recommendations to you about goods or
            services that may be of interest to you.
          </p>
          <p className="text-sm text-gray-700 mt-4">
            3.3 Change of purpose We will only use your Personal Data for the
            purposes for which we collected it unless we reasonably consider
            that we need to use it for another reason and that reason is
            compatible with the original purpose. If you wish to get an
            explanation as to how the processing for the new purpose is
            compatible with the original purpose, please contact our DPO.
          </p>
          <h3 className="text-xl font-semibold mt-4">4. Data Sharing</h3>
          <ul className="list-disc text-gray-700 text-sm pl-9 mt-4">
            <li>
              Third Party Service Providers: We may share your Personal Data
              with third-party service providers who assist us in providing our
              services. These include payment processors, IT service providers,
              and marketing agencies.
            </li>
            <li>
              Professional Advisers: We may need to share your Personal Data
              with professional advisers such as lawyers, bankers, auditors, and
              insurers.
            </li>
            <li>
              Regulators and Authorities: We may need to share your Personal
              Data with regulators and other authorities to comply with legal
              obligations.
            </li>
            <li>
              Business Transfers: If we are involved in a merger, acquisition,
              or sale of assets, your Personal Data may be transferred as part
              of that transaction.
            </li>
          </ul>
          <p className="text-sm text-gray-700 mt-4">
            4.2 Data security We take appropriate measures to protect your
            Personal Data from unauthorized access, alteration, disclosure, or
            destruction. However, please note that no system or transmission of
            data over the internet is completely secure.
          </p>
          <h3 className="text-xl font-semibold mt-4">5. Data Retention</h3>
          <p className="text-sm text-gray-700 mt-4">
            5.1 Data retention period We will retain your Personal Data only for
            as long as necessary to fulfill the purposes we collected it for,
            including for the purposes of satisfying any legal, accounting, or
            reporting requirements.
          </p>
          <p className="text-sm text-gray-700 mt-4">
            5.2 Disposal of Data When we no longer need your Personal Data, we
            will securely delete or anonymize it in accordance with our data
            retention policy.
          </p>
          <h3 className="text-xl font-semibold mt-4">
            6. Your Data Protection Rights
          </h3>
          <p className="text-sm text-gray-700 mt-4">
            6.1 Your rights Under data protection laws, you have the following
            rights:
          </p>
          <ul className="list-disc text-gray-700 text-sm pl-9 mt-4">
            <li>
              Access: You have the right to request a copy of the Personal Data
              we hold about you.
            </li>
            <li>
              Rectification: You have the right to request that we correct any
              inaccurate or incomplete Personal Data we hold about you.
            </li>
            <li>
              Erasure: You have the right to request that we delete your
              Personal Data under certain circumstances.
            </li>
            <li>
              Restriction: You have the right to request that we restrict the
              processing of your Personal Data under certain circumstances.
            </li>
            <li>
              Portability: You have the right to request that we transfer your
              Personal Data to another organization or to you, under certain
              circumstances.
            </li>
            <li>
              Objection: You have the right to object to the processing of your
              Personal Data under certain circumstances.
            </li>
          </ul>
          <p className="text-sm text-gray-700 mt-4">
            6.2 Exercising your rights To exercise any of these rights, please
            contact our DPO using the contact details provided in section 1.2.
          </p>
          <h3 className="text-xl font-semibold mt-4">
            7. Changes to This Privacy Policy
          </h3>
          <p className="text-sm text-gray-700 mt-4">
            We may update this Privacy Policy from time to time. Any changes
            will be posted on this page and, where appropriate, notified to you
            by email. Please check back frequently to see any updates or changes
            to our Privacy Policy.
          </p>
          <h3 className="text-xl font-semibold mt-4">8. Contact Us</h3>
          <p className="text-sm text-gray-700 mt-4">
            If you have any questions about this Privacy Policy or our data
            practices, please contact us at:
          </p>
          <ul className="list-none text-gray-700 text-sm pl-9 mt-4">
            <li>Email: 0</li>
            <li>
              Postal address: Tradal Ltd, 11 Sunnymede, Chigwell, Essex IG76ES,
              United Kingdom
            </li>
          </ul>

          <h3 className="text-xl font-semibold mt-4">
            9. Cookies and Tracking Technologies
          </h3>
          <p className="text-sm text-gray-700 mt-4">
            9.1 Use of Cookies Our website uses cookies and similar tracking
            technologies to enhance your browsing experience, analyze site
            usage, and assist in our marketing efforts. Cookies are small data
            files placed on your device that help us recognize you and remember
            your preferences.
          </p>
          <p className="text-sm text-gray-700 mt-4">
            9.2 Types of Cookies We Use
          </p>
          <ul className="list-disc text-gray-700 text-sm pl-9 mt-4">
            <li>
              Essential Cookies: These are necessary for the operation of our
              website and to provide services you have requested, such as
              remembering your login details or preferences.
            </li>
            <li>
              Performance Cookies: These cookies collect information about how
              visitors use our website, such as which pages they visit most
              often. This helps us improve the performance of our website.
            </li>
            <li>
              Functionality Cookies: These cookies allow our website to remember
              choices you make, such as your username or language preference,
              and provide enhanced, more personalized features.
            </li>
            <li>
              Targeting Cookies: These cookies are used to deliver
              advertisements that are relevant to you and your interests. They
              may also be used to limit the number of times you see an
              advertisement and help measure the effectiveness of advertising
              campaigns.
            </li>
          </ul>
          <p className="text-sm text-gray-700 mt-4">
            9.3 Managing Cookies You can manage your cookie preferences through
            your browser settings. Most browsers allow you to refuse cookies or
            delete them. Please note that if you choose to disable cookies, some
            parts of our website may not function properly.
          </p>
          <p className="text-sm text-gray-700 mt-4">
            9.4 Third-Party Cookies We may also use third-party cookies provided
            by our partners and service providers. These cookies are governed by
            the respective privacy policies of those third parties. For more
            information about third-party cookies, please refer to their privacy
            policies.
          </p>
          <h3 className="text-xl font-semibold mt-4">
            10. International Data Transfers
          </h3>
          <p className="text-sm text-gray-700 mt-4">
            10.1 Transfers Outside the UK If we need to transfer your Personal
            Data to countries outside the UK, we will ensure that appropriate
            safeguards are in place to protect your data. This may include:
          </p>
          <ul className="list-disc text-gray-700 text-sm pl-9 mt-4">
            <li>
              Adequacy Decisions: Transferring data to countries deemed to
              provide an adequate level of protection by the UK government.
            </li>
            <li>
              Standard Contractual Clauses: Using contracts approved by the UK
              Information Commissioner's Office that require recipients to
              protect your data.
            </li>
            <li>
              Binding Corporate Rules: Implementing internal policies and
              procedures to ensure data protection across our global operations.
            </li>
          </ul>
          <p className="text-sm text-gray-700 mt-4">
            10.2 Your Rights with International Transfers You have the right to
            be informed about the safeguards in place for international data
            transfers. If you have any questions about these transfers or
            require further information, please contact our DPO.
          </p>
          <h3 className="text-xl font-semibold mt-4">
            11. Automated Decision-Making and Profiling
          </h3>
          <p className="text-sm text-gray-700 mt-4">
            11.1 Automated Decision-Making We may use automated decision-making
            processes, including profiling, to make decisions about you based on
            your Personal Data. For example, this may include assessing your
            eligibility for certain services or offers.
          </p>
          <p className="text-sm text-gray-700 mt-4">
            11.2 Your Rights You have the right to object to automated
            decision-making, including profiling, that has legal or similarly
            significant effects on you. If you wish to exercise this right or if
            you have concerns about how automated decisions are made, please
            contact our DPO.
          </p>
          <h3 className="text-xl font-semibold mt-4">12. Children's Data</h3>
          <p className="text-sm text-gray-700 mt-4">
            12.1 Age Restrictions Our website and services are not intended for
            individuals under the age of 16. We do not knowingly collect
            Personal Data from children under 16. If we become aware that we
            have collected Personal Data from a child without parental consent,
            we will take steps to delete that data.
          </p>
          <p className="text-sm text-gray-700 mt-4">
            12.2 Parental Consent If you are a parent or guardian and believe
            your child has provided us with Personal Data, please contact us so
            we can address the issue.
          </p>
          <h3 className="text-xl font-semibold mt-4">
            13. Links to Other Websites
          </h3>
          <p className="text-sm text-gray-700 mt-4">
            13.1 Third-Party Links Our website may contain links to third-party
            websites. We are not responsible for the privacy policies or
            practices of those third-party sites. We encourage you to review the
            privacy policies of any third-party websites you visit.
          </p>
          <h3 className="text-xl font-semibold mt-4">14. Data Breaches</h3>
          <p className="text-sm text-gray-700 mt-4">
            14.1 Reporting Breaches In the event of a Personal Data Breach, we
            will notify you and the relevant supervisory authority in accordance
            with applicable data protection laws. We will provide information on
            the nature of the breach, its potential consequences, and the
            measures we are taking to address it.
          </p>
          <h3 className="text-xl font-semibold mt-4">
            15. Questions and Concerns
          </h3>
          <p className="text-sm text-gray-700 mt-4">15.1 Contact Us</p>
          <p className="text-sm text-gray-700 mt-2">
            If you have any questions, concerns, or complaints about this
            Privacy Policy or our data protection practices, please contact us
            using the details provided in section 8. We are committed to
            resolving any issues you may have.
          </p>
          <p className="text-sm text-gray-700 mt-4">
            15.2 Complaints to the ICO If you are dissatisfied with our response
            to your concerns, you have the right to lodge a complaint with the
            Information Commissioner's Office (ICO), the UK supervisory
            authority for data protection issues.
          </p>
          {/* Close Button */}
          <button
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
            onClick={onClose}
          >
            &times;
          </button>
        </div>
      </div>
    </>
  );
}
