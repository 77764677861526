import React from "react";
import Circle_Img from "../../assests/Images/Illusration5.png";

const Leading = () => {
  return (
    <div className="bg-gray-50 py-16">
      <div className="mx-auto w-full max-w-6xl p-4">
        <div className="text-start">
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-semibold mb-11 text-golden">
            Sustainability & Diversity
          </h1>

          <p className="text-base sm:text-lg mb-6 md:mb-10 text-gray-600">
            Tradal is committed to sustainability, inclusivity, transparency,
            and efficiency, aiming to transform commodity trading into a more
            accessible and eco-friendly ecosystem for businesses, governments,
            and SMEs.
          </p>
          <p className="text-base sm:text-lg mb-6 md:mb-10 text-gray-600">
            The platform seeks to reduce its carbon footprint by 76% over the
            next five years through advanced data analytics and optimised supply
            chain logistics. By fully automating processes and eliminating
            reliance on physical documents, Tradal enhances efficiency and helps
            clients lower their own carbon footprints.
          </p>
          <p className="text-base sm:text-lg mb-6 md:mb-10 text-gray-600">
            With automation reducing turnaround times from months to minutes and
            offering real-time tracking, clients can make quick, informed
            decisions. Accessible on iOS, Android, and PDA devices, Tradal
            ensures flexibility and responsiveness.
          </p>
          <p className="text-base sm:text-lg mb-6 md:mb-10 text-gray-600">
            By valuing diversity, Tradal strengthens partnerships with
            governments and high-volume traders, facilitating pan-African trade
            and economic growth while reshaping commodity trading for a
            sustainable future.
          </p>
        </div>

        <div className="flex justify-center mt-8 md:mt-10 ">
          <img
            src={Circle_Img}
            className="w-full max-w-2xl rounded-4xl"
            alt="Illustration showing leadership in sustainable trading"
          />
        </div>
      </div>
    </div>
  );
};

export default Leading;
