import React from "react";
import phoneImage1 from "../../assests/Images/dealRoom.png";

const SecureDealRoom = () => {
  return (
    <div className="bg-white py-16">
      <div className="mx-auto w-full max-w-6xl p-4">
        {/* Title */}
        <h1 className="text-3xl sm:text-4xl md:text-5xl font-semibold text-golden mb-6">
          Secure Deal Room
        </h1>

        {/* Main Content Section */}
        <div className="flex flex-col lg:flex-row lg:space-x-10">
          {/* Text Section */}
          <div className="text-gray-700 lg:w-2/3 space-y-6">
            <p>
              On Tradal Go, we identify and remove non-performers early, before
              issuing a Tradal Passport. Our secure deal room access policy for
              secure, transparent, fast, efficient, and reliable trading. Secure
              transactions are backed by Letters of Credit (LC) and include
              end-to-end cataloguing.
            </p>

            <p>
              Tracking from origin, refinement to consumer, ensuring
              transparency and efficiency. Through its Eco-Cycle-System, a
              4-step solution focused on energy conservation and the
              preservation of natural resources.
            </p>

            <p>
              Tradal seeks to eradicate the negative impact on the global
              commodities environment. Offering and promoting ethical trade
              practices in exchange for long-term, sustainable investment
              opportunities.
            </p>
          </div>

          {/* Image Section */}
          <div className="flex justify-center lg:justify-end mt-10 lg:mt-0 lg:w-1/2">
            <img
              src={phoneImage1}
              alt="Trade Successful"
              className="w-40 md:w-48 lg:w-[22rem]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecureDealRoom;
