import React from "react";
import Logo from "../../assests/Images/tradalLogo.png";

const Header = () => {
  return (
    <>
      <div className="sticky top-0 z-50 bg-black h-16 px-4 md:px-10 lg:px-20 xl:px-40 flex items-center justify-between">
        {/* Left side: Logo and tagline */}
        <div>
          <img src={Logo} height={40} width={150} className="py-1" alt="logo" />
          <p className="text-golden text-xs mb-4 font-normal ml-2">
            Commodities Marketplace
          </p>
        </div>

        {/* Right side: List items */}

        <ul className="flex space-x-1 text-golden text-sm">
          <li>Secure </li>
          <li>Transparent</li>
          <li>Fast</li>
        </ul>
      </div>
    </>
  );
};

export default Header;
