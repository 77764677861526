import "./App.css";
import Header from "./Components/Header/Header";
import HeadSection from "./Components/Sectionone/HeadSection";
import Ecological from "./Components/Sectionthree/Ecological";
import StepTradingProcess from "./Components/Sectionsix/TradingProcess";
import Partnership from "./Components/Sectioneight/Partnership";
import Leading from "./Components/Sectioneleven/Leading";
import Supporters from "./Components/Sectiontwelve/Supporters";
import Footer from "./Components/Footer/Footer";
import About from "./Components/Sectiontwo/About";
import DataSecurity from "./Components/Sectionten/DataSecurity";
import Solution from "./Components/Sectiontwo/Solution";
import SecureDealRoom from "./Components/Sectionsix/SecureDealRoom";
function App() {
  return (
    <>
      <Header />
      <HeadSection />
      <About />
      <Solution />
      <Ecological />
      <StepTradingProcess />
      <SecureDealRoom />
      <DataSecurity />
      <Partnership />
      <Leading />
      <Supporters />
      <Footer />
    </>
  );
}

export default App;
