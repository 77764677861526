import React from "react";
import style from "./HeadSection.module.css";
import intro from "../../assests/Images/into.png";

const HeadSection = () => {
  return (
    <div className="py-16 bg-white">
      <div className="mx-auto w-full max-w-6xl p-4">
        <div className="text-start">
          <img className={style.img3} />
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-semibold mb-11 text-golden">
            Where Sustainability & Profitability Coexist
          </h1>

          <div className="flex flex-col md:flex-row items-start gap-8">
            {/* Left content section */}
            <div className="w-full md:w-3/5">
              <div className=" p-6 mb-6 ">
                <p className="text-base sm:text-lg text-gray-600 mb-4">
                  Tradal is a Global Trading Platform Pairing Buyers from the
                  UK, Europe, and North America with Confirmed Trade Partners in
                  Africa.
                </p>
                <p className="text-base sm:text-lg text-gray-600">
                  Our Web 3.0 platform reduce trade times from months to Minutes
                  focused on sustainably & lowering Tradal's and our clients
                  carbon footprint by 73% in 5 years
                </p>
              </div>
            </div>

            {/* Right image section */}
            <div className="w-full md:w-2/5 flex justify-center mt-8 md:mt-0">
              <img
                src={intro}
                alt="Intro"
                className="w-full max-w-2xl rounded-2xl"
              />
            </div>
          </div>

          <p className="text-center mt-8">
            Tradal, where sustainability and profitability coexist.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HeadSection;
