import React, { useState } from "react";
import Logo from "../../assests/Images/tradalLogo.png";

import PrivacyModal from "../Modals/privacyModal";
import TermsModal from "../Modals/termsModal";
import CookiesModal from "../Modals/cookiesModal";

const Footer = () => {
  const [openPrivacy, setOpenPrivacy] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);
  const [openCookies, setOpenCookies] = useState(false);
  return (
    <footer className="bg-black text-gray-400 py-10">
      <div className="container mx-auto flex flex-wrap justify-between px-6 lg:px-20">
        <div className="w-full lg:w-1/4 mb-6 lg:mb-0">
          <div>
            <img
              src={Logo}
              height={40}
              width={150}
              className="py-1"
              alt="logo"
            />
            <p className="text-golden text-xs font-normal ml-2">
              Commodities Marketplace
            </p>
          </div>
        </div>

        <nav className="w-1/2 md:w-1/4 lg:w-1/6 mb-6 lg:mb-0">
          {/* <h3 className="font-semibold mb-4">Legal</h3> */}
          <ul className="space-y-2">
            <li>
              <button
                className="hover:text-yellow-400"
                aria-label="Privacy"
                onClick={() => setOpenPrivacy(true)}
              >
                Privacy
              </button>
            </li>

            {openPrivacy && (
              <PrivacyModal onClose={() => setOpenPrivacy(false)} />
            )}
            <li>
              <button
                className="hover:text-yellow-400"
                aria-label="Terms"
                onClick={() => setOpenTerms(true)}
              >
                Terms
              </button>
            </li>
            {openTerms && <TermsModal onClose={() => setOpenTerms(false)} />}
            <li>
              <button
                className="hover:text-yellow-400"
                aria-label="Cookies"
                onClick={() => setOpenCookies(true)}
              >
                Cookies
              </button>
            </li>
            {openCookies && (
              <CookiesModal onClose={() => setOpenCookies(false)} />
            )}
          </ul>
        </nav>

        <div className="w-full md:w-1/2 lg:w-1/4">
          <h3 className="font-semibold mb-4">Contact Info</h3>
          <a href="mailto:info@tradalcommodity.com" className="mb-2">
            info@tradalcommodity.com
          </a>
          {/* <p className="mb-4">+91 99999 99999</p> */}
          <div className="flex space-x-4 text-lg">
            <a
              href="#"
              className="hover:text-yellow-400"
              aria-label="Facebook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook"></i>
            </a>
            <a
              href="#"
              className="hover:text-yellow-400"
              aria-label="Twitter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-twitter"></i>
            </a>
            <a
              href="#"
              className="hover:text-yellow-400"
              aria-label="Instagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              href="#"
              className="hover:text-yellow-400"
              aria-label="LinkedIn"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-linkedin"></i>
            </a>
          </div>
        </div>
      </div>

      <div className="text-center mt-10 text-gray-600 text-sm">
        © 2024 Tradal. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
